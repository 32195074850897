<template>
  <div class="create-meeting">
    <v-dialog
      v-model="dialog"
      max-width="500"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      content-class="create-meeting"
      transition="dialog-bottom-transition">

          <v-card class="pb-6">
            <div class="header bg-loop pa-4">
              <h3 class="white--text text-center text-shadow">Meet with {{loadedUser.data.firstName}}</h3>

              <v-row align="center" justify="center" no-gutters class="pa-4">
                <v-col cols="4">
                  <v-img :src="localUser.data.photoURL.cropped" class="rounded shadow"></v-img>
                </v-col>
                <v-col cols="auto" class="float">
                  <v-icon size="45" class="mx-4  white--text">mdi-handshake-outline</v-icon>
                </v-col>
                <v-col cols="4">
                  <v-img :src="loadedUser.data.photoURL.cropped" class="rounded shadow"></v-img>
                </v-col>
              </v-row>

              <v-btn @click="closeProposal" color="white" outlined fab small class="close"><v-icon size="26">mdi-close</v-icon></v-btn>
            </div>
            <div class="px-4 pt-6">


                <div class="d-flex align-center mb-1">
                  <h4 class="">1. Let's chat about</h4>
                  <template v-if="showErrors && error.text"><small class="error--text ml-2">This helps make the meeting personal.</small></template>
                </div>
                <v-textarea
                solo flat background-color="grey lighten-3"
                auto-grow
                :placeholder="`Give ${loadedUser.data.firstName} an idea what you want to meet about...`"
                rows="1"
                row-height="20"
                hide-details
                v-model.trim="meeting.description"
                @blur="checkErrors"
                ></v-textarea>


                <div class="d-flex align-center mt-6 mb-1">
                  <h4 >2. Choose topics</h4>
                  <template v-if="showErrors && error.topics"><small class="error--text ml-2">We need this for ratings.</small></template>
                </div>
                <p class="caption">You'll rate {{loadedUser.data.firstName}} on these after the meeting.</p>

                <div class="d-flex flex-wrap mb-4">
                  <div v-for="(skill, i) in loadedUser.data.skills" :key="skill.name">
                    <select-topic :topic="skill" @selected-topic="toggleTopic"></select-topic>
                  </div>
                </div>

                <div class="d-flex align-center mb-1">
                  <h4>3. Propose some times</h4>
                  <template v-if="showErrors && error.times"><small class="error--text ml-2">Dates/times are kinda important.</small></template>
                </div>
                <choose-time :loadedUser="loadedUser.data" :localUser="localUser.data" :canASAP="true" @dates="selectDates" @send-soon="sendASAP"></choose-time>

            <v-divider class="my-4"></v-divider>

            <v-btn color="black" dark class="submit mx-auto py-6"  @click="submitProposal">Request Proposal <span class="px-2" style="opacity:.5">|</span><strong>${{loadedUser.data.rate}}</strong></v-btn>
          </div>
          </v-card>
        </v-dialog>
  </div>

</template>

<script>
const selectTopic = () => import("@/components/shared/select-topic.vue");
const chooseTime = () => import("@/components/shared/choose-time.vue");
import { cloud, db } from '@/firebase';

export default {
  name: 'createProposal',
  components:{selectTopic, chooseTime},
  props:['loadedUser', 'localUser'],
  data: () => ({
    dialog:true,
    activeSkill:false,
    noTopics: false,
    meeting:{
      active: true,
      description:"",
      topics:[],
      dates: [],
      accepted:false,
      client: null,
      advisor: null,
      turn:'advisor'
    },
    error:{
      text: true,
      topics: true,
      times: true
    },
    showErrors: false,
  }),
  methods:{
    toggleTopic(skill, index){
      let vThis = this;
      //toggle  main class
      this.activeSkill = !this.activeSkill;

      //set meeting stopics
      let existsAlready = vThis.loadedUser.data.skills.findIndex(function(post){
        // console.log(val, post.name,post.name == val);
        if (skill.name == skill){
          return true
        }
      });
      if(existsAlready == -1){
        vThis.meeting.topics.push(skill);
        vThis.checkErrors()
      }
      else{
        vThis.meeting.topics.splice(existsAlready, 1);
      }

    },
    selectDates(data){
      let vThis = this;
      let utcDates = data.map( item =>{
        process.env.NODE_ENV === "development" ? console.log( 'selectDates data', vThis.$date(item.date).format() ) : null;
        process.env.NODE_ENV === "development" ? console.log( 'selectDates data', vThis.$date.utc(item.date).format() ) : null;
        process.env.NODE_ENV === "development" ? console.log( 'isUTC', vThis.$date.utc(item.date).isUTC() ) : null;
        process.env.NODE_ENV === "development" ? console.log( 'isUTC', vThis.$date(item.date).tz('America/New_York').format() ) : null;
        return {date: vThis.$date.utc(item.date).format(), rejected: false, who: "client"}
      })
      this.meeting['dates'] = utcDates;
      this.checkErrors()
    },
    sendASAP(data){
      process.env.NODE_ENV === "development" ? console.log( 'send ASAP?', data ) : null;
      this.meeting['sendASAP'] = data;
      if(data === false && !this.$isUndefined(this.meeting.dates)){
        this.meeting.dates = [];
      }
      this.checkErrors()
    },
    async checkErrors(){
      this.error.text = this.meeting.description.length > 10 ? false : true;
      this.error.topics = this.meeting.topics.length >= 1 ? false : true;
      this.error.times = this.meeting.sendASAP === true || this.meeting.dates.length >= 1 ? false : true;
      process.env.NODE_ENV === "development" ? console.log( 'error times', this.meeting.sendASAP === true,  this.meeting.dates.length >= 1, this.meeting.sendASAP === true || this.meeting.dates.length >= 1   ) : null;
      let value = await this.error.text === false || this.error.topics === false || this.error.times === false;
      return value
    },
    submitProposal(){
      let vThis = this;
      let meeting = {...vThis.meeting};
        //start loading for saving
        this.checkErrors().then(value =>{
          process.env.NODE_ENV === "development" ? console.log( 'value', value, vThis.error ) : null;
          if(value === true ){
            vThis.isSaving = true;
            db.collection('proposals').add(meeting).then(result=>{
              process.env.NODE_ENV === "development" ? console.log( 'posted meeting?', meeting, result ) : null;
              vThis.closeProposal();
            })
          }
          else{
            this.showErrors = true
          }
        });

    },
    closeProposal(){
      this.$emit('close-meeting', true);
    },
    setupProposal(){
      process.env.NODE_ENV === "development" ? console.log( 'localUser, loadedUser', this.localUser, this.loadedUser ) : null;
      this.meeting.client = {
        firstName:this.localUser.data.firstName,
        lastName:this.localUser.data.lastName,
        userId:this.localUser.userId,
        photoURL_cropped: this.localUser.data.photoURL.cropped,
        photoURL_thumb: this.localUser.data.photoURL.thumb,
        userName: this.localUser.data.userName
      };
      this.meeting.advisor = {
        firstName:this.loadedUser.data.firstName,
        lastName:this.loadedUser.data.lastName,
        userId:this.loadedUser.userId,
        photoURL_cropped: this.loadedUser.data.photoURL.cropped,
        photoURL_thumb: this.loadedUser.data.photoURL.thumb,
        userName: this.loadedUser.data.userName
      }
      this.meeting.attendees_Ids = [this.loadedUser.userId, this.localUser.userId];
    },
  },
  mounted(){
    this.setupProposal();
  }
};
</script>


<style lang="scss">
.v-dialog.create-meeting{
    max-height: 100%;

  .header{
    position: relative;
    .close{
      position: absolute;
      top: 10px;
      right:10px;
    }
  }
  .submit{
    width: 94%!important;
    margin: 0 auto;
    display: flex;
  }
  // .hiddenInput{
  //   visibility: hidden;
  //   position: absolute;
  //   z-index: -1;
  // }
}
</style>
